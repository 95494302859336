import React, { ReactNode } from "react"

import { Helmet } from "react-helmet";

import { Col, Row, Carousel } from "antd";

import "antd/dist/antd.css";

import * as styles from "./index.module.scss"

interface IcenteredLogo {
    imgPath: string,
    style?: any,
}

function CenteredLogo({ imgPath, style }: IcenteredLogo) {
    return <div className={styles.centered_logo} style={{ ...style }}>
        <img src={imgPath} alt="centereimg" />
    </div>
}

interface IflexBox {
    children: ReactNode | ReactNode[],
    style?: any,
}
function FlexBox({ children, style }: IflexBox) {
    return <div className={styles.flex_box} style={{ ...style }}>
        {children}
    </div>
}

interface Iflex1 {
    title?: string,
    details: string,
    data?: {
        main: string,
        extra: string
    }
}
function Flex1Item({ title, details, data }: Iflex1) {
    return <div className={styles.flex1_item}>
        {data && <div className={styles.data}>{data.main}<span>{data.extra}</span></div>}
        {title && <div className={styles.second_title} style={{ marginBottom: "20px" }}>{title}</div>}
        <div className={styles.text} style={{ lineHeight: "30px" }}>{details}</div>
    </div>
}

interface Iflex {
    style?: any,
    imgPath: string,
    title: string,
    details: string
}
function FlexItem({ style, imgPath, title, details }: Iflex) {
    return <div className={styles.flex_item} style={{ ...style, textAlign: "center" }}>
        <img src={imgPath} alt="img" style={{ width: "70px", height: "70px" }} />
        <div className={styles.second_title} style={{ marginTop: "20px" }}>{title}</div>
        <div className={styles.text} style={{ marginTop: "20px", fontSize: "14px", textAlign: "center" }} >{details}</div>
    </div>
}

interface Ititle {
    children: ReactNode | string,
    style?: any
}
function Title({ children, style }: Ititle) {
    return <div className={styles.title} style={{ ...style }}>{children}</div>
}

function getImg(name: string) {
    return require(`@images/advertising/${name}.png`).default
}

//适配移动端的一些组件
interface IcardShadow {
    children: ReactNode | ReactNode[]
}
function CardShadow({ children }: IcardShadow) {
    return <div className={styles.card_shadow}>
        {children}
    </div>
}

export default function Advertising() {
    const facebookData1 = [
        {
            data: { main: "43.82", extra: "%" },
            details: "针对Niche市场的锁定精准程度"
        }, {
            data: { main: "1/3", extra: "" },
            details: "全球1/3人口真实身份/真实位置/真实爱好"
        }, {
            data: { main: "20亿", extra: "+" },
            details: "每月20亿+活跃账号"
        },
    ]
    const facebookData2 = [
        {
            img: "icon1",
            title: "创建Facebook主页",
            details: "主页是Facebook用户认识您的窗口，也是您展示自家品牌形象的舞台，帮助您与潜在受众建立联系，推广业务"
        },
        {
            img: "icon2",
            title: "开通Facebook广告账户",
            details: "Facebook委托国内广告代理商及经销商为中国出海广告商提供一系列专业服务和协助"
        }, {
            img: "icon3",
            title: "定位目标受众",
            details: "根据尼尔森线上营销活动评估，Facebook的精确度是其他广告网络的2倍以上"
        }, {
            img: "icon4",
            title: "确定推广目标",
            details: "Facebook有13 种不同的广告目标。对于经营电商广告商来说，增加网站转换量、吸引更多网站访客是首选的广告目标"
        }, {
            img: "icon5",
            title: "创建Facebook广告",
            details: "Facebook广告由 3 部分组成：广告系列、广告组和广告。创建广告时，始终以创建广告系列开始"
        }, {
            img: "icon6",
            title: "选择广告创意",
            details: "Facebook拥有视频广告、幻灯片广告、链接式广告、轮播广告、360全景广告、Instagram快拍广告等广告创意"
        }, {
            img: "icon7",
            title: "Messenger",
            details: "Messenger方便商家轻松与用户建立直接联系。还可以设置自动回复以及创建 Messenger 智能助手"
        }, {
            img: "icon8",
            title: "效果评估",
            details: "通过Facebook监控工具，帮助您了解广告效果，方便让您及时调整广告策略，改善广告效果"
        },
    ]

    const googleData1 = [{
        title: "精准定位目标客户",
        details: "通过定位地理位置、语言、展示位置、设备和受众群体等，准确的找到你想要的客户"
    }, {
        title: "免费展示广告信息",
        details: "仅在潜在客户点击广告访问你的网站时才收费，如潜在客户看到广告但没有点击则不收费，也就是免费展示广告信息"
    }, {
        title: "搜索引擎市场份额全球第一",
        details: "全球搜索引擎市场份额Google占比92.6%，是第二名的45倍"
    }]

    const googleData2 = [
        {
            img: "icon9",
            title: "关键词",
            details: "更全面的关键词覆盖，细致入微的匹配设置，让您的每一分钱都出效果"
        }, {
            img: "icon10",
            title: "创意文案",
            details: "我们提供优秀的海外创意文案，让您的广告效果卓尔不群"
        }, {
            img: "icon11",
            title: "服务",
            details: "我们团队提供多对一的专业服务，想您所想，卡点问题全力解决"
        }, {
            img: "icon12",
            title: "分析报告",
            details: "每周为您提供上周总结报告，每月月度总结让您轻松掌控账户情况"
        }, {
            img: "icon13",
            title: "二次营销",
            details: "私域运营为您不断降低获客成本，促使客户进行购买，提高转化率"
        },
    ]

    const bingData1 = [
        {
            title: "高购买力用户", details: "比平均联网用户高101%，比Google高40%"
        },
        {
            title: "覆盖多个平台和终端", details: "办公or娱乐，PC/平板/手机，全方位全场景广告完美注入"
        }, {
            title: "定位精确", details: "可根据设备、地域、时间等进行定向广告，精准锁定目标用户"
        },
    ]

    const bingData2 = [
        {
            head: "全球",
            percentage: "10%",
            data: "110亿"
        }, {
            head: "北美",
            percentage: "34%",
            data: "62.8亿"
        }, {
            head: "欧洲",
            percentage: "9%",
            data: "20亿"
        }, {
            head: "亚太地区",
            percentage: "4%",
            data: "20亿"
        },
        {
            head: "拉美",
            percentage: "6%",
            data: "7.41亿"
        },
    ]

    const ourserviseData = [
        {
            img: "icon14",
            title: "免费广告开户",
            details: "免费提供Facebook/Google/TikTok开户服务"
        },
        {
            img: "icon15",
            title: "定制海外营销解决方案",
            details: "量身定制营销计划，付费/自然流量、私域流量、用户裂变有机组合"
        }, {
            img: "icon16",
            title: "全天候广告审核服务",
            details: "实时追踪、专业审核、避免违规、事故及时处理"
        }, {
            img: "icon17",
            title: "全程技术对接服务",
            details: "专业技术团队支持、专业的全链路解决方案"
        }, {
            img: "icon18",
            title: "资深跨境服务团队",
            details: "跨境电商行业十余年老牌团队，深谙成交逻辑"
        }, {
            img: "icon19",
            title: "数据支持服务",
            details: "专业团队产出市场分析数据，用数据决胜未来"
        }, {
            img: "icon20",
            title: "广告优化服务",
            details: "专业优化师团队在线引导，只为让您的每一分钱都花得更值"
        }, {
            img: "icon21",
            title: "广告创意服务",
            details: "提供广告创意支撑、广告素材制作及优化服务"
        },
    ]
    return <>
        <Helmet title='推广服务-Zero Miracle' />
        <div className={styles.advertising}>
            {/* facebook  */}
            <div id='facebook' />
            <CenteredLogo imgPath={getImg('facebook')} />
            <Row>
                <Col md={24} sm={0} xs={0}>
                    <FlexBox style={{ marginTop: "30px" }}>
                        {facebookData1.map((item, index) => <Flex1Item data={item.data} details={item.details} key={index} />)}
                    </FlexBox></Col>
                <Col md={0} sm={24} xs={24}>
                    <Carousel>
                        {
                            facebookData1.map((item, index) => <div key={index} className={styles.card_out}>
                                <CardShadow>
                                    <Flex1Item data={item.data} details={item.details} />
                                </CardShadow >
                            </div>)
                        }

                    </Carousel>
                </Col>
            </Row>

            <Title style={{ marginTop: "40px" }}>Facebook推广服务</Title>
            <FlexBox style={{ flexWrap: "wrap" }}>
                {facebookData2.map((item, index) =>
                    <FlexItem key={index} imgPath={getImg(item.img)} title={item.title} details={item.details}
                    />)}
            </FlexBox>

            {/* google */}

            <div id='google' />
            <div style={{ backgroundColor: "#F9FBFE", padding: "1px" }}>
                <CenteredLogo imgPath={getImg("google")} style={{ marginTop: "60px" }} />
                <Row>
                    <Col md={24} sm={0} xs={0}>
                        <FlexBox style={{ marginTop: "30px" }}>
                            {googleData1.map((item, index) => <Flex1Item title={item.title} details={item.details} key={index} />)}
                        </FlexBox></Col>
                    <Col md={0} sm={24} xs={24}>
                        <Carousel>
                            {
                                googleData1.map((item, index) => <div key={index} className={styles.card_out}>
                                    <CardShadow>
                                        <Flex1Item title={item.title} details={item.details} />
                                    </CardShadow >
                                </div>)
                            }
                        </Carousel>
                    </Col>
                </Row>
                <Title style={{ marginTop: "40px" }}>Google推广服务</Title>
                <FlexBox>
                    {googleData2.map((item, index) =>
                        <FlexItem key={index} imgPath={getImg(item.img)} title={item.title} details={item.details}
                        />)}
                </FlexBox>
            </div>

            {/* bing */}
            <div id='bing' />
            <CenteredLogo imgPath={getImg("bing")} style={{ marginTop: "40px" }} />


            <Row>
                <Col md={24} sm={0} xs={0}>
                    <FlexBox style={{ marginTop: "30px" }}>
                        {bingData1.map((item, index) => <Flex1Item title={item.title} details={item.details} key={index} />)}
                    </FlexBox></Col>
                <Col md={0} sm={24} xs={24}>
                    <Carousel>
                        {
                            bingData1.map((item, index) => <div key={index} className={styles.card_out}>
                                <CardShadow>
                                    <Flex1Item title={item.title} details={item.details} />
                                </CardShadow >
                            </div>)
                        }

                    </Carousel>
                </Col>
            </Row>

            <div className={styles.bing_second}>必应广告网络遍布全球</div>

            <Row>
                <Col md={24} sm={0} xs={0}>
                    <div className={styles.earthContainer}>
                        <div>
                            <img src={getImg("earth")} alt="earth" />
                        </div>
                        <FlexBox style={{ position: "absolute", bottom: "-46px", width: "100%" }}>
                            {
                                bingData2.map(item =>
                                    <div className={styles.bing_item} key={item.head}>
                                        <div className={styles.head}>{item.head}</div>
                                        <div style={{ backgroundColor: "rgba(255,255,255,.8)", padding: "1px" }}>
                                            <div className={styles.added} style={{ marginTop: "30px" }}>{item.percentage}</div>
                                            <div className={styles.overhere}>搜索市场份额</div>
                                            <div className={styles.added} style={{ marginTop: "20px" }}>{item.data}</div>
                                            <div className={styles.overhere} style={{ marginBottom: "30px" }}>月度搜索</div>
                                        </div>
                                    </div>
                                )
                            }
                        </FlexBox>
                    </div>
                </Col>
                <Col md={0} sm={24} xs={24}>
                    <FlexBox style={{ padding: "30px 20px", justifyContent: "space-between" }}>
                        {
                            bingData2.map(item =>
                                <div className={styles.bing_item} key={item.head} style={{ width: "160px" }}>
                                    <div className={styles.head}>{item.head}</div>
                                    <div style={{ backgroundColor: "rgba(255,255,255,.8)", padding: "1px" }}>
                                        <div className={styles.added} style={{ marginTop: "30px" }}>{item.percentage}</div>
                                        <div className={styles.overhere}>搜索市场份额</div>
                                        <div className={styles.added} style={{ marginTop: "20px" }}>{item.data}</div>
                                        <div className={styles.overhere} style={{ marginBottom: "30px" }}>月度搜索</div>
                                    </div>
                                </div>
                            )
                        }
                    </FlexBox>
                </Col>
            </Row>


            {/* 我们的服务*/}

            <div id='our' />
            <Row>
                <Col md={24} sm={0} xs={0}>
                    <div className={styles.ourservise}>
                        <img src={getImg('ourservise')} alt="" />
                        <h1>我们的服务</h1>
                        <h3>全流程/全方位/全心全意</h3>
                    </div>
                </Col>
                <Col md={0} sm={24} xs={24}>
                    <div className={styles.ourservise_mobile}>
                        <img src={getImg('ourservise_mobile')} alt="" />
                        <h1>我们的服务</h1>
                        <h3>全流程/全方位/全心全意</h3>
                    </div>
                </Col>
            </Row>


            <FlexBox style={{ flexWrap: "wrap" }}>
                {ourserviseData.map((item, index) =>
                    <FlexItem key={index} imgPath={getImg(item.img)} title={item.title} details={item.details}
                    />)}
            </FlexBox>
            {/* <div className={styles.consult_now}>
                <div className={styles.left}>立即咨询</div>
                <div className={styles.right}>bd@Zero Miracle.com</div>
            </div> */}
        </div>
    </>
}