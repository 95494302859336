// extracted by mini-css-extract-plugin
export var added = "index-module--added--df3ea";
export var advertising = "index-module--advertising--de8a0";
export var bing_item = "index-module--bing_item--f9774";
export var bing_second = "index-module--bing_second--0ee1f";
export var card_out = "index-module--card_out--093b2";
export var card_shadow = "index-module--card_shadow--17765";
export var centered_logo = "index-module--centered_logo--9eea8";
export var consult_now = "index-module--consult_now--5ed7f";
export var data = "index-module--data--da287";
export var earthContainer = "index-module--earthContainer--f313d";
export var flex1_item = "index-module--flex1_item--cfa25";
export var flex_box = "index-module--flex_box--5c6f9";
export var flex_item = "index-module--flex_item--cd807";
export var head = "index-module--head--e9460";
export var left = "index-module--left--753dc";
export var ourservise = "index-module--ourservise--41934";
export var ourservise_mobile = "index-module--ourservise_mobile--235bd";
export var overhere = "index-module--overhere--75007";
export var right = "index-module--right--1a729";
export var second_title = "index-module--second_title--8ea53";
export var text = "index-module--text--4430f";
export var title = "index-module--title--41c9e";